<template>
  <div class="icp-word" v-loading="loading">
    <div class="icp-word-left">
      <div class="t article_t"><span class="border_c">付费资讯</span></div>
      <!-- <div style="margin-top: 20px">
        <form action="" name="search_form" method="post" class="article_search">
          <input
            name="keywords"
            type="text"
            id="requirement"
            value=""
            class="fd_input"
            placeholder="请输入您要找的文章关键字"
            autocomplete="off"
          />
          <input
            type="submit"
            value="立即搜索"
            class="fd_submit bg_c"
            disabled
          />
        </form>
      </div> -->
      <el-row v-for="(item, index) in config.config" :key="index" class="list">
        <!-- <el-tooltip
          effect="dark"
          :content="`${price}元付费阅读`"
          placement="bottom"
        >
          
        </el-tooltip> -->
        <el-col>
          <el-row>
            <el-col :span="4">
              <el-image
                :src="require('@/assets/imgs/icp/1630651620172336685.jpg')"
              ></el-image>
            </el-col>
            <el-col :span="20">
              <div class="list-title">
                <dl>
                  <span class="title" @click="click(index)">{{
                    item.title
                  }}</span>
                </dl>
                <dl>...</dl>
                <dl style="margin-top: 10px">{{ price }}元付费阅读</dl>
              </div>
              <div style="float: right; font-size: 14px; color: #909399">
                <dl>作者：云淘通</dl>
                <dl>时间：2022-05-26</dl>
              </div>
            </el-col>
          </el-row>
          <div></div>
          <div></div>
        </el-col>
      </el-row>
    </div>
    <div class="AreaR">
      <div class="rightbox" :class="{ fixed: scrollTop > 200 }">
        <div class="leftbox">
          <div class="t"><span class="border_c">文章分类</span></div>
          <div class="info">
            <a href="#/icp/read" class="cat word_c">付费资讯</a>
            <div class="clear0"></div>
          </div>
        </div>
        <div class="leftbox">
          <div class="t"><span class="border_c">推荐资讯</span></div>
          <div class="info">
            <ol type="1">
              <li
                v-for="(item, index) in getTopWord"
                :key="index"
                class="topword"
              >
                {{ item.title }}
              </li>
            </ol>
          </div>
        </div>
      </div>
      <div class="clear0"></div>
    </div>
    <div v-html="alipayHtml" ref="alipayWap"></div>
    <el-dialog :visible.sync="dialogVisible" width="40%" class="dialog">
      <!-- <el-image
        style="width: 500px; height: 500px"
        :src="qrCodeUrl"
        fit="contain"
        lazy
      ></el-image> -->
      <!-- <span>这是一段信息</span> -->
      <!-- <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="isPay">确 定</el-button>
      </span> -->
      <div style="text-align: center">
        <div
          id="weChatQrcode"
          ref="weChatQrcode"
          style="margin: 0 auto; width: 230px; height: 230px"
        ></div>
        <p
          style="
            font-weight: bold;
            margin-top: 2%;
            line-height: 30px;
            color: red;
          "
        >
          ￥{{ price }}
        </p>
        <p style="font-weight: bold; margin-top: 2%; line-height: 30px">
          使用微信扫码支付
        </p>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import config from "@/common/config/common/icpword.config.js";
import QRCode from "qrcodejs2";
export default {
  data() {
    return {
      dialogVisible: false,
      config,
      index: -1,
      guid: "",
      price: 10,
      alipayHtml: "",
      loading: false,
      qrCodeUrl: "", //require('@/assets/imgs/pay/alipay.png')
      tradeNo: "",
      wxpayTimer: null,
      number: 0, //计算器秒数
      scrollTop: 0,
    };
  },
  activated() {
    this.index = -1;
  },
  onPageScroll(e) {
    console.log(e, 123);
    this.scrollTop = e.scrollTop;
  },
  computed: {
    getTopWord() {
      return config.config.slice(1, 11);
    },
  },
  watch: {
    dialogVisible(e) {
      if (e) {
        this.$nextTick(function () {
          new QRCode(this.$refs.weChatQrcode, {
            width: 230,
            height: 230,
            text: this.qrCodeUrl,
            correctLevel: QRCode.CorrectLevel.H,
          });
          clearInterval(this.wxpayTimer);
          this.wxpayTimer = setInterval(this.paystatus, 3000);
        });
      } else {
        this.$refs.weChatQrcode.innerHTML = "";
        clearInterval(this.wxpayTimer);
      }
    },
  },
  mounted() {
    window.addEventListener("scroll", this.getScrollTop); // 监听滚动事件，然后用handleScroll这个方法进行相应的处理
  },
  methods: {
    getScrollTop() {
      var scrollTop =
        window.pageYOffset || //⽤于FF
        document.documentElement.scrollTop ||
        document.body.scrollTop ||
        0;
      this.scrollTop = scrollTop;
    },
    click(val) {
      this.loading = true;
      this.index = val;
      this.guid = localStorage.getItem("guid");
      //生成并缓存guid
      if (!this.guid) {
        this.guid = this.$util.guid();
        localStorage.setItem("guid", this.guid);
      }
      var data = this.config.config[this.index];
      var payUrl = "/api/WechatPayment/QrCodePayByIcp";
      this.$axios
        .get(payUrl, {
          params: {
            userId: this.guid,
            price: this.price,
            goodsId: data.id,
          },
        })
        .then((res) => {
          this.loading = false;
          if (res.data !== undefined && res.data !== null && res.data !== "") {
            this.qrCodeUrl = res.data[0];
            this.tradeNo = res.data[1];
            //window.location.href = "/#/user/wxrecharge?url=" +res.data[0] +"&id=" +res.data[1];
            this.dialogVisible = true;
          } else {
            this.$alert("提交失败,刷新后重试。", "系统提示", {
              confirmButtonText: "确定",
              closeOnClickModal: true,
            });
            return;
          }
        })
        .catch((error) => {
          this.loading = false;
        });
    },
    isPay() {
      this.loading = true;
      this.dialogVisible = false;
      //this.$router.push({ path: "/icp/word", query: { id: this.index } });
      var data = this.config.config[this.index];
      // this.$axios
      //   .get("/api/payment/RechargeIcpByAlipay", {
      //     params: {
      //       userId: this.guid,
      //       price: this.price,
      //       goodsId: data.id,
      //     },
      //   })
      //   .then((res) => {
      //     this.alipayHtml = res.data;
      //     this.$nextTick(() => {
      //         this.$refs.alipayWap.children[0].submit();
      //       });
      //      this.loading=false
      //   })
      //   .catch((error) => {
      //     this.loading=false
      //   });
    },
    paystatus() {
      this.number++;
      if (this.tradeNo.length > 0 && this.number <= 600) {
        //  this.dialogVisible = false;
        //  this.$router.push({ path: "/icp/word", query: { id: this.index } })
        this.$axios
          .get(
            "/api/WechatPayment/QueryByOutTradeNo?outTradeNo=" + this.tradeNo
          )
          .then((res) => {
            if (res.data != "NOTPAY") {
              clearInterval(this.wxpayTimer);
              this.dialogVisible = false;
              this.$router.push({
                path: "/icp/word",
                query: { id: this.index },
              });
            }
          })
          .catch((err) => {
            return Promise.reject(err);
          });
      }

      if (this.number > 600) {
        clearInterval(this.wxpayTimer);
        this.$axios
          .get("/api/WechatPayment/OutTradeNoClose?outTradeNo=" + this.id)
          .then((res) => {
            this.dialogVisible = false;
            this.$alert("订单超时，已关闭", "系统提示", {
              confirmButtonText: "确定",
              callback: (action) => {
                this.$router.go(0);
              },
            });
          })
          .catch((err) => {
            return Promise.reject(err);
          });
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.icp-word {
  max-width: 1224px;
  margin: auto;
  .icp-word-left {
    border: 1px solid #ededed;
    padding: 0px;
    position: relative;
    background: #fff;
    margin-bottom: 15px;
    width: 900px;
    float: left;
    .list {
      border-bottom: 1px dotted #ededed;
      padding-bottom: 20px;
      padding-top: 20px;
      width: 95%;
      margin: auto;
      .list-title {
        padding-left: 40px;
        font-size: 14px;
        color: #909399;
        .title {
          font-size: 18px;
          cursor: pointer;
          color: black;
          &:hover {
            // text-decoration: underline;
            color: #ff6701;
          }
        }
      }
    }
    .article_search {
      width: 90%;
      background: #f8f8f8;
      padding: 15px;
      margin: auto;
      border-radius: 10px;
    }
    .fd_submit {
      height: 30px;
      line-height: 30px;
      margin-right: 15px;
      color: #fff;
      padding-left: 15px;
      padding-right: 15px;
      border: 0px;
      cursor: pointer;
      background-color: #ff6700;
    }
    input.fd_input {
      width: 70%;
      border: 1px solid #eee;
      background: #fff;
      height: 35px;
      line-height: 35px;
      padding-left: 5px;
      font-size: 14px;
      margin-right: 10px;
    }
  }
  .AreaR {
    width: 300px;
    float: right;
    .rightbox {
      &.fixed {
        position: fixed;
        top: 0;
        // left: 0;
        // right: 0;
        animation: open 0.5s forwards;

        @keyframes open {
          from {
            margin-top: -50%;
          }

          to {
            margin-top: 0;
          }
        }
      }
    }
    .leftbox {
      width: 300px;

      border: 1px solid #ededed;
      padding: 0px;
      position: relative;
      background: #fff;
      margin-bottom: 15px;

      .info {
        padding: 10px;
        .cat {
          border: 1px solid #ddd;
          margin: 10px;
          padding-left: 10px;
          padding-right: 10px;
          float: left;
          display: block;
          line-height: 30px;
          background: #f5f5f5;
          font-size: 14px;
          color: #ff6700;
          &:hover {
            color: #ff4a00;
          }
        }
        .topword {
          margin-bottom: 60px;
          cursor: pointer;
          list-style-position: inside;
          list-style-type: auto;
          text-overflow: ellipsis;
          overflow: hidden;
          white-space: nowrap;

          &:hover {
            text-decoration: underline;
            color: #ff6701;
          }
        }
      }
    }
    .clear0 {
      clear: both;
      padding: 0px;
      margin: 0px;
    }
  }

  .t {
    background: #e9e9e9;
    padding-top: 5px;
    height: 35px;
    line-height: 30px;
    position: relative;
    span {
      border-left-width: 3px;
      border-left-style: solid;
      padding-left: 10px;
      font-size: 14px;
      font-weight: bold;
      color: #333;
      border-color: #ff6700;
    }
  }
  ::v-deep .el-dialog__body {
    display: flex;
    justify-content: center;
    align-items: center;
    //   padding: 0 ;
  }
  ::v-deep .el-dialog__footer {
    text-align: center;
  }
}
</style>
